import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/workspace/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_t2GMTTFnMT from "/workspace/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/workspace/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import at_client_M7FyHMC438 from "/workspace/plugins/at.client.js";
import formatters_0SUx5x5qFy from "/workspace/plugins/formatters.js";
import gtm_client_KEcT5dOoKs from "/workspace/plugins/gtm.client.js";
import version_client_NNUwgnDNz9 from "/workspace/plugins/version.client.js";
import vuetify_8NhHJigKc1 from "/workspace/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_t2GMTTFnMT,
  plugin_AUP22rrBAc,
  at_client_M7FyHMC438,
  formatters_0SUx5x5qFy,
  gtm_client_KEcT5dOoKs,
  version_client_NNUwgnDNz9,
  vuetify_8NhHJigKc1
]