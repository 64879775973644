import { defineStore } from 'pinia';

const initialState = {
  user: {
    id: null,
    phone: null,
    ip: null,
    city: null,
    state: null,
    postal: null,
    loggedIn: false,
  },
  refer: {
    ineligible: false,
    referer: null,
    reasons: [],
  },
  flash: {
    message: null,
  },
  tenant: {
    id: null,
    domain: null,
  },
  experiments: [
    {
      name: 'welcome-screen',
      variants: ['simple-layout', 'photo-and-rates'],
      selected: null,
    },
    {
      name: 'welcome-message',
      variants: ['from-pre-approval', 'from-mortgage'],
      selected: null,
    },
    {
      name: 'welcome-rates',
      variants: ['with-rates', 'without-rates'],
      selected: null,
    },
  ],
};

export const useNurlaStore = defineStore('nurla', {
  state: () => initialState,
  getters: {},
  actions: {
    resetState () {
      this.user = { ...initialState.user };
      this.refer = { ...initialState.refer };
    },
    setUser (payload) {
      this.user = { ...this.user, ...payload };
    },
    setRefer (payload) {
      this.refer = { ...this.refer, ...payload };
    },
    setFlash (payload) {
      this.flash = { ...this.flash, ...payload };
    },
    setTenant (payload) {
      this.tenant = { ...this.tenant, ...payload };
    },
    getExperimentVariant (name) {
      const i = this.experiments.findIndex(ex => ex.name === name);
      if (i >= 0) {
        const experiment = this.experiments[i];

        if (experiment.selected !== null) {
          return experiment.selected;
        }

        const j = Math.floor(Math.random() * experiment.variants.length);
        const selected = experiment.variants[j];
        this.experiments[i].selected = selected;

        useGtm().trackEvent({
          event: 'Nurla Experiment',
          eventCategory: useRoute().name,
          eventLabel: name,
          eventValue: selected,
        });

        return selected;
      }

      throw new Error(`'${name}' experiment not found`);
    },
  },
  persist: {
    afterRestore: ($pinia) => {
      const experiments = [];
      for (const experiment of initialState.experiments) {
        const i = $pinia.store.experiments.findIndex(e => e.name === experiment.name);
        if (i >= 0) {
          experiments.push({
            ...experiment,
            ...$pinia.store.experiments[i],
          });
        } else {
          experiments.push(experiment);
        }
      }
      $pinia.store.experiments = experiments;
    },
  },
});
